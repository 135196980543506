import type { SetRule } from "@/shared/plugin/acl/acl.type";
import { AclRole, AclRules } from "@/shared/plugin/acl/acl.type";
import { Employee } from "@finapp/proto/pkg-ts/common/common";
import { Absence } from "@finapp/proto/pkg-ts/common/absence_objects";
import { AbsenceForm, AbsenceReason, AbsenceRequest, AbsenceStatus } from "@/store/modules/absence/absence.types";
import { useAcl } from "vue-simple-acl/src";
import { getAbsenceSelfApprovalPermissionName } from "@/shared/plugin/acl/acl.helper";
import { AbsenceFormState } from "@/components/absences/absences-form/composables/use-absences-form";
import { AdvanceReportStatus } from "@/store/modules/advance-reports/advance-reports.types";
import { getDateFromTimeStamp } from "@/shared/helper/api.helper";

const getAbsenceResolvedForEditStatusByAbsenceReason = (absenceReason: AbsenceReason): AbsenceStatus[] => {
  switch (absenceReason) {
    case AbsenceReason.DayOff:
    case AbsenceReason.Training:
    case AbsenceReason.DistantWork:
    case AbsenceReason.Absenteeism:
      return [
        AbsenceStatus.ApprovedManager,
        AbsenceStatus.Accepted,
        AbsenceStatus.AwaitingSignEmployee,
        AbsenceStatus.DigitallySigned,
        AbsenceStatus.PhysicallySigned,
      ];
    case AbsenceReason.BusinessTrip:
    case AbsenceReason.Vacation:
    case AbsenceReason.OwnExpenseVacation:
    case AbsenceReason.Illness:
      return [
        AbsenceStatus.Accepted,
        AbsenceStatus.AwaitingSignEmployee,
        AbsenceStatus.DigitallySigned,
        AbsenceStatus.PhysicallySigned,
      ];
  }
};

const { role, anyRole, permission } = useAcl();

export function setAbsenceAclRules(setRule: SetRule) {
  setRule<(user: Employee, request: Absence) => void>(AclRules.BusinessTripsEdit, (user: Employee, request) => {
    return (
      (request.employee?.portalCode === user.portalCode || anyRole([AclRole.Admin, AclRole.Support])) &&
      ((request.status as AbsenceStatus) === AbsenceStatus.NeedChange ||
        (request.status as AbsenceStatus) === AbsenceStatus.Draft ||
        (request.status as AbsenceStatus) === AbsenceStatus.RevokeForChanging)
    );
  });

  setRule<(user: Employee, request: Absence) => void>(AclRules.AbsenceChangeDate, (user: Employee, request) => {
    const allowedStatuses: AbsenceStatus[] = [AbsenceStatus.ApprovedManager, AbsenceStatus.Accepted];
    return (
      (((request.absenceReason as AbsenceReason) === AbsenceReason.BusinessTrip &&
        request.employee?.portalCode === user.portalCode) ||
        ((request.absenceReason as AbsenceReason) === AbsenceReason.BusinessTrip &&
          anyRole([AclRole.Admin, AclRole.Support]))) &&
      allowedStatuses.includes(request.status as AbsenceStatus)
    );
  });

  setRule<(user: Employee, request: Absence) => void>(AclRules.BusinessTripsChangeStatus, (user: Employee, request) => {
    const permissionName = getAbsenceSelfApprovalPermissionName(request.absenceReason as AbsenceReason);
    const managerRole = anyRole([AclRole.Admin, AclRole.Support, AclRole.Director, AclRole.ApprovingAgent]);
    const managerPermission =
      user.portalCode === request.chief?.portalCode ||
      anyRole([AclRole.Admin, AclRole.Support]) ||
      user.portalCode === request.selectedApprovingManager?.portalCode ||
      anyRole([AclRole.Admin, AclRole.Support]);

    return (
      ((managerRole && managerPermission) ||
        (request.employee &&
          request.employee.portalCode === user.portalCode &&
          permissionName &&
          permission(permissionName))) &&
      (request.status as AbsenceStatus) === AbsenceStatus.AwaitingApprovalManager
    );
  });

  setRule<(user: Employee, request: AbsenceRequest) => void>(
    AclRules.BusinessTripCreateAdvanceReport,
    (user: Employee, request) => {
      return (
        request.employee?.portalCode === user.portalCode &&
        (request.absenceReason as AbsenceReason) === AbsenceReason.BusinessTrip &&
        (request.status === AbsenceStatus.ApprovedManager ||
          request.status === AbsenceStatus.Accepted ||
          request.status === AbsenceStatus.AwaitingSignEmployee ||
          request.status === AbsenceStatus.PhysicallySigned ||
          request.status === AbsenceStatus.DigitallySigned) &&
        !request.advanceReport?.id
      );
    },
  );

  setRule<(user: Employee, request: AbsenceFormState) => void>(
    AclRules.AbsenceChangeAbsenceReason,
    (user: Employee, request) => {
      return (
        request.status !== AbsenceStatus.NeedChange &&
        request.status !== AbsenceStatus.Draft &&
        request.status !== AbsenceStatus.RevokeForChanging
      );
    },
  );

  setRule<(user: Employee, request: Absence) => void>(AclRules.AbsenceRevoke, (user, request) => {
    return (
      anyRole([
        AclRole.Accountant,
        AclRole.Support,
        AclRole.User,
        AclRole.Director,
        AclRole.ApprovingAgent,
        AclRole.Admin,
      ]) &&
      request.employee &&
      request.employee.portalCode === user.portalCode &&
      (request.status as AbsenceStatus) === AbsenceStatus.AwaitingApprovalManager
    );
  });

  // Просмотр приказа на командировку
  setRule<(user: Employee, request: Absence) => void>(AclRules.AbsenceBusinessTripOrderView, (user, request) => {
    const employeeRole = request.employee?.portalCode === user.portalCode || role(AclRole.Admin);
    const employeeStatus = [
      AbsenceStatus.AwaitingSignEmployee,
      AbsenceStatus.PhysicallySigned,
      AbsenceStatus.DigitallySigned,
      AbsenceStatus.Accepted,
    ].includes(request.status as AbsenceStatus);

    const accountantRole = anyRole([AclRole.Admin, AclRole.Accountant]);
    const accountantStatus = [
      AbsenceStatus.AwaitingSignEmployee,
      AbsenceStatus.PhysicallySigned,
      AbsenceStatus.DigitallySigned,
      AbsenceStatus.Accepted,
    ].includes(request.status as AbsenceStatus);

    const directorRole = anyRole([AclRole.Director, AclRole.ApprovingAgent, AclRole.Admin]);

    const adminRole = anyRole([AclRole.Admin, AclRole.Support]);

    return (employeeRole && employeeStatus) || ((accountantRole || directorRole) && accountantStatus) || adminRole;
  });

  // Подписание приказа на командировку
  setRule<(user: Employee, request: Absence) => void>(AclRules.AbsenceBusinessTripsDigitalSign, (user, request) => {
    const employeeRole = request.employee?.portalCode === user.portalCode || role(AclRole.Admin);
    const employeeStatus = [AbsenceStatus.AwaitingSignEmployee].includes(request.status as AbsenceStatus);

    const employeeDS = request.employee?.hasDigitalSignature || false;

    return employeeRole && employeeStatus && employeeDS;
  });
  setRule<(user: Employee, request: Absence) => void>(AclRules.AbsenceBusinessTripsPhysicalSign, (user, request) => {
    const status = [AbsenceStatus.AwaitingSignEmployee, AbsenceStatus.AwaitingSignEmployee].includes(
      request.status as AbsenceStatus,
    );

    const role = anyRole([AclRole.Admin, AclRole.Support, AclRole.Accountant]);
    return status && role;
  });

  setRule<(user: Employee, request: Absence) => void>(AclRules.AbsenceEditAfterAccepted, (user: Employee, request) => {
    return (
      (request.employee?.portalCode === user.portalCode || anyRole([AclRole.Admin, AclRole.Support])) &&
      (request.status as AbsenceStatus) === AbsenceStatus.RevokeForChanging
    );
  });

  setRule<(user: Employee, request: AbsenceRequest) => void>(
    AclRules.AbsenceCancelAfterAccepted,
    (user: Employee, request) => {
      const absenceStatusPermission = getAbsenceResolvedForEditStatusByAbsenceReason(
        request.absenceReason as AbsenceReason,
      ).includes(request.status);

      const absenceReasonPermission = [
        AbsenceReason.BusinessTrip,
        AbsenceReason.Vacation,
        AbsenceReason.OwnExpenseVacation,
      ].includes(request.absenceReason as AbsenceReason);

      const userPermission =
        request.employee?.portalCode === user.portalCode || anyRole([AclRole.Admin, AclRole.Support]);

      const absenceAdvanceReport = request.advanceReport?.id;

      return absenceReasonPermission && absenceStatusPermission && !absenceAdvanceReport && userPermission;
    },
  );

  setRule<(user: Employee, request: AbsenceRequest) => boolean>(
    AclRules.AbsenceRevokeAfterAccepted,
    (user, request) => {
      const rolePermission = anyRole([AclRole.Admin, AclRole.Support]);

      const userPermission = request.employee?.portalCode === user.portalCode;

      const absenceStatusPermission = getAbsenceResolvedForEditStatusByAbsenceReason(
        request.absenceReason as AbsenceReason,
      ).includes(request.status);

      const absenceStarted = getDateFromTimeStamp(request.startDate).getTime() < new Date().getTime();
      const absenceAdvanceReport = !!request.advanceReport?.id;
      const absenceAdvanceReportAccepted = [
        AdvanceReportStatus.ApprovedAccountant,
        AdvanceReportStatus.Accepted,
        AdvanceReportStatus.AwaitingSignEmployee,
        AdvanceReportStatus.Signed,
        AdvanceReportStatus.PhysicallySigned,
        AdvanceReportStatus.DigitallySigned,
      ].includes(request?.advanceReport?.status as AdvanceReportStatus);

      return (
        (rolePermission || userPermission) && // Роль
        absenceStatusPermission && // Статус
        !(absenceStarted && absenceAdvanceReport && absenceAdvanceReportAccepted) // Командировка началась, и имеет согласованный АО
      );
    },
  );

  // Подписание приказа на отпуск
  setRule<(user: Employee, request: AbsenceRequest) => boolean>(AclRules.AbsenceVacationOrderSign, (user, request) => {
    const employeeRole = request.employee?.portalCode === user.portalCode || role(AclRole.Admin);
    const employeeStatus = [AbsenceStatus.AwaitingSignEmployee].includes(request.status);
    const employeeDS = request.employee?.hasDigitalSignature || false;

    const accountantRole = anyRole([AclRole.Admin, AclRole.Accountant]);

    return (employeeRole && employeeStatus && employeeDS) || (accountantRole && employeeDS);
  });

  // Просмотр приказа на отпуск
  setRule<(user: Employee, request: AbsenceRequest) => boolean>(AclRules.AbsenceVacationOrderView, (user, request) => {
    const employeeRole = request.employee?.portalCode === user.portalCode || role(AclRole.Admin);
    const employeeStatus = [
      AbsenceStatus.AwaitingSignEmployee,
      AbsenceStatus.PhysicallySigned,
      AbsenceStatus.DigitallySigned,
      AbsenceStatus.Accepted,
    ].includes(request.status);

    const accountantRole = anyRole([AclRole.Admin, AclRole.Support, AclRole.Accountant]);
    const accountantStatus = [
      AbsenceStatus.AwaitingSignEmployee,
      AbsenceStatus.PhysicallySigned,
      AbsenceStatus.DigitallySigned,
      AbsenceStatus.Accepted,
    ].includes(request.status);
    return (employeeRole && employeeStatus) || (accountantRole && accountantStatus);
  });

  // Подписание заявления на отпуск
  setRule<(user: Employee, request: AbsenceRequest) => boolean>(
    AclRules.AbsenceVacationRequestSign,
    (user, request) => {
      const employeeRole =
        request.employee?.portalCode === user.portalCode || anyRole([AclRole.Admin, AclRole.Support]);
      const employeeStatus = [AbsenceStatus.AwaitingSignEmployee].includes(request.status);
      const employeeDS = user.hasDigitalSignature;

      return employeeRole && employeeStatus && employeeDS;
    },
  );

  // Просмотр заявления на отпуск
  setRule<(user: Employee, request: AbsenceRequest) => boolean>(
    AclRules.AbsenceVacationRequestView,
    (user, request) => {
      const employeeRole =
        request.employee?.portalCode === user.portalCode || anyRole([AclRole.Admin, AclRole.Support]);
      const employeeStatus = [
        AbsenceStatus.AwaitingSignEmployee,
        AbsenceStatus.PhysicallySigned,
        AbsenceStatus.DigitallySigned,
        AbsenceStatus.Accepted,
      ].includes(request.status);

      const accountantRole = anyRole([AclRole.Admin, AclRole.Support, AclRole.Accountant]);
      const accountantStatus = [
        AbsenceStatus.AwaitingSignEmployee,
        AbsenceStatus.PhysicallySigned,
        AbsenceStatus.DigitallySigned,
        AbsenceStatus.Accepted,
      ].includes(request.status);

      return (employeeRole && employeeStatus) || (accountantRole && accountantStatus);
    },
  );

  setRule<(user: Employee, request: AbsenceForm, isApprovedEarlier: boolean) => boolean>(
    AclRules.AbsenceEditReplacementManager,
    (user, form, isApprovedEarlier) => {
      const isAdmin = anyRole([AclRole.Admin, AclRole.Support]);
      const requestIsActive =
        form.startDate &&
        form.id &&
        new Date(form.startDate).setDate(form.startDate.getDate() - 1) <= new Date().getTime();
      return isAdmin || !(isApprovedEarlier && requestIsActive);
    },
  );

  setRule<(user: Employee, form: AbsenceForm, isApprovedEarlier: boolean) => boolean>(
    AclRules.AbsenceEditApprovingManager,
    (user, form, isApprovedEarlier) => {
      return anyRole([AclRole.Admin, AclRole.Support]) || !isApprovedEarlier;
    },
  );

  setRule(AclRules.AbsenceSetReplacementManager, () => {
    return anyRole([AclRole.Admin, AclRole.Support, AclRole.Director, AclRole.ApprovingAgent]);
  });

  // Удаление черновика ЗНО
  setRule<(user: Employee, request: AbsenceRequest) => boolean>(AclRules.AbsenceRemoveDraft, (user, request) => {
    const statusPermission = [AbsenceStatus.Draft].includes(request.status);
    const employeePermission = user.portalCode === request.employee?.portalCode;

    const isAdmin = anyRole([AclRole.Admin, AclRole.Support]);
    return statusPermission && (employeePermission || isAdmin);
  });
}
