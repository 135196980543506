import { RouteType } from "@/router/route.type";
import { RouteRecordRaw } from "vue-router";
import { RequestWithCommentsView } from "@/components/request/request-with-comments/request-with-comments.types";

export const advanceReportsRoutes: RouteRecordRaw = {
  path: "/advance-reports",
  name: RouteType.AdvanceReports,
  redirect: {
    name: RouteType.AdvanceReportsList,
  },
  component: () => import("@/views/advance-reports/advance-reports.vue"),
  children: [
    {
      path: "",
      name: RouteType.AdvanceReportsList,
      component: () => import("@/views/advance-reports/advance-reports-list-page/advance-reports-list-page.vue"),
      meta: {
        title: "Авансовый отчёт",
      },
    },
    {
      path: ":reportId/:action(\\approve|improve|cancellation)?",
      name: RouteType.AdvanceReportsItem,
      component: () => import("@/views/advance-reports/advance-reports-item-page/advance-reports-item.vue"),
      meta: {
        breadcrumbs: [
          {
            label: "Авансовые отчёты",
            route: { name: RouteType.AdvanceReportsList },
          },
        ],
      },
      props: {
        visibleView: RequestWithCommentsView.View,
      },
    },
    {
      path: ":reportId/comments",
      name: RouteType.AdvanceReportsComment,
      redirect: (to) => ({
        name: RouteType.AdvanceReportsItem,
        params: {
          reportId: to.params.reportId,
        },
        query: {
          tab: "comments",
        },
      }),
    },
    {
      path: "create",
      name: RouteType.AdvanceReportsCreate,
      component: () => import("@/views/advance-reports/create-advance-report/create-advance-report.vue"),
      meta: {
        title: "Авансовый отчёт",
      },
      props: (route) => ({
        businessTripId: route.query.business_trip_id,
      }),
    },
    {
      path: "edit/:reportId",
      name: RouteType.AdvanceReportsEdit,
      component: () => import("@/views/advance-reports/edit-advance-report/edit-advance-report.vue"),
      meta: {
        title: "Авансовый отчёт",
      },
    },
  ],
};
