import { useAcl } from "vue-simple-acl/src";
import { AclRole, AclRules, SetRule } from "@/shared/plugin/acl/acl.type";
import { Employee } from "@finapp/proto/pkg-ts/common/common";
import { SubReportRequest } from "@/store/modules/sub-reports/sub-reports.types";
import { SubReportStatus } from "@/store/modules/sub-reports";

const { anyRole } = useAcl();

export function setSubReportAclRules(setRule: SetRule) {
  setRule<(user: Employee, subReport: SubReportRequest) => void>(AclRules.SubReportEdit, (user, subReport) => {
    const userPermission =
      subReport.creator?.portalCode === user.portalCode || anyRole([AclRole.Admin, AclRole.Support]);
    const statusPermission = [
      SubReportStatus.Draft,
      SubReportStatus.NeedChange,
      SubReportStatus.RevokeForChanging,
    ].includes(subReport.status);
    return userPermission && statusPermission;
  });

  setRule<(user: Employee, subReport: SubReportRequest) => void>(AclRules.SubReportRevoke, (user, subReport) => {
    const userPermission =
      subReport.creator?.portalCode === user.portalCode || anyRole([AclRole.Admin, AclRole.Support]);
    const statusPermission = [SubReportStatus.AwaitingApproval].includes(subReport.status);
    return userPermission && statusPermission;
  });

  setRule<(user: Employee, subReport: SubReportRequest) => void>(AclRules.SubReportImprove, (user, subReport) => {
    const userPermission =
      subReport.subreport.reviewer?.portalCode === user.portalCode || anyRole([AclRole.Admin, AclRole.Support]);
    const statusPermission = [SubReportStatus.AwaitingApproval].includes(subReport.status);
    return userPermission && statusPermission;
  });

  setRule<(user: Employee, subReport: SubReportRequest) => void>(AclRules.SubReportCancellation, (user, subReport) => {
    const userPermission =
      subReport.subreport.reviewer?.portalCode === user.portalCode || anyRole([AclRole.Admin, AclRole.Support]);
    const statusPermission = [SubReportStatus.AwaitingApproval].includes(subReport.status);
    return userPermission && statusPermission;
  });

  setRule<(user: Employee, subReport: SubReportRequest) => void>(AclRules.SubReportApprove, (user, subReport) => {
    const userPermission =
      subReport.subreport.reviewer?.portalCode === user.portalCode || anyRole([AclRole.Admin, AclRole.Support]);
    const statusPermission = [SubReportStatus.AwaitingApproval].includes(subReport.status);
    return userPermission && statusPermission;
  });

  // Удаление черновика Подотчета
  setRule<(user: Employee, subReport: SubReportRequest) => void>(AclRules.SubReportRemove, (user, subReport) => {
    const statusPermission = [SubReportStatus.Draft].includes(subReport.status);
    const employeePermission = user.portalCode === subReport.creator?.portalCode;

    const isAdmin = anyRole([AclRole.Admin, AclRole.Support]);
    return statusPermission && (employeePermission || isAdmin);
  });
}
